import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row, media } from "styled-bootstrap-grid"
import styled from "styled-components"

import { GetInTouch, Layout, Paragraph, SEO, SocialMedia } from "../components"
import { Colors } from "../utils/constants"

const Technology = ({ data }) => {
  const posts = data.postBlock.nodes[0].post.map(item => ({
    title: item.title,
    description: item.content.content,
    fixedSources: item.thumbnail?.localFile?.childImageSharp,
  }))
  const socialMedias = data.socialMedias.nodes[0]
  const getInTouch = data.getInTouch.nodes.map(item => ({
    title: item.title,
    description: item.content.content,
    locations: item.location.map(location => ({
      country: location.country,
      address1: location.addressLine1,
      address2: location.addressLine2,
      zip: location.postalCode,
      phone: location.phone,
    })),
    cards: item.meetingCards.map(item => ({
      icon: item.icon.file.url,
      title: item.title,
      titleColor: Colors.RED_2,
      description: item.content.content,
      link: { label: item.title, href: item.link.url },
    })),
  }))[0]

  return (
    <Layout>
      <SEO title="What we do" />
      <StyledContainer fluid>
        {posts.map(({ title, description, fixedSources }, index) => (
          <StyledContainerWithMarginTop key={index}>
            <Row>
              <Col col="12">
                <Paragraph
                  title={title}
                  content={description}
                  fixedSources={fixedSources}
                  isReversed={index % 2 === 1}
                  isSmall={index}
                />
              </Col>
            </Row>
          </StyledContainerWithMarginTop>
        ))}
      </StyledContainer>
      {/* get in touch section */}
      <GetInTouchContainer>
        <GetInTouch
          title={getInTouch.title}
          content={getInTouch.description}
          locations={getInTouch.locations}
          meetingCards={getInTouch.cards}
        />
      </GetInTouchContainer>

      {/* social media section */}
      <SocialMedia socialMedias={socialMedias} />
    </Layout>
  )
}

const GetInTouchContainer = styled(Container)`
  ${media.lg`
margin-top : 230px;
`}
`
const StyledContainer = styled(Container)`
  ${media.md`
    & > div:first-of-type {
      height: auto;
    }
  `}
`

const StyledContainerWithMarginTop = styled(Container)`
  margin-bottom: 70px;
`

export default Technology

export const query = graphql`
  query technologyQuery(
    $page: ContentfulPageFilterListInput = {
      elemMatch: { title: { eq: "Technology" } }
    }
  ) {
    postBlock: allContentfulPostBlock(filter: { page: $page }) {
      nodes {
        ...contentBlock
        title
        post {
          ...post
          thumbnail {
            localFile {
              childImageSharp {
                mobileImage: fixed(toFormat: WEBP, width: 250, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
                tabletImage: fixed(toFormat: WEBP, width: 300, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
                desktopImage: fixed(toFormat: WEBP, width: 460, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    getInTouch: allContentfulMeetingBlock(filter: { page: $page }, limit: 1) {
      nodes {
        ...meetingBlock
      }
    }
    socialMedias: allContentfulSocialMediaGroup {
      nodes {
        ...socialMedia
      }
    }
  }
`
